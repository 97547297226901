<template>
  <div>
    <b-button v-if="!noBtn" variant="success" @click="toggleModal()">
      <i class="uil uil-plus-circle mr-1"></i>
      {{ $t('buttons.add') }}
    </b-button>
    <b-modal v-model="isModalActive" hide-footer title="เพิ่ม การแจ้งเตือนไลน์">
      <ValidationObserver ref="lineNotificationForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
            <AgentsSelectInput
              :value="lineForm.agentId"
              label-cols="3"
              @update="(agentId) => lineForm.agentId = agentId"
            />
          <b-form-group
            label="เลือกประเภท"
            label-cols-lg="3"
            label-cols-sm="12"
            label-for="type"
          >
            <b-select id="type" v-model="lineForm.type">
              <b-form-select-option
                v-for="(type, typeI) in typeOptions"
                :key="typeI"
                :value="type.value"
                >{{ type.name }}
              </b-form-select-option>
            </b-select>
          </b-form-group>
          <b-form-group
            label="ชื่อ"
            label-cols-lg="3"
            label-cols-sm="12"
            label-for="name"
          >
            <b-input id="name" v-model="lineForm.name"> </b-input>
          </b-form-group>
          <b-form-group
            label="Token"
            label-cols-lg="3"
            label-cols-sm="12"
            label-for="token"
          >
            <b-input id="token" v-model="lineForm.token"> </b-input>
          </b-form-group>

          <div class="text-right">
            <b-button class="mr-2" variant="light" @click="onCloseModal">
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              :show="isLoading"
              class="d-inline-block"
              rounded
              spinner-small
            >
              <b-button block type="submit" variant="primary">
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'LineNotificationForm',
  components: {
    AgentsSelectInput: () => import('@components/agents/agents-select-input'),
  },
  props: {
    selectedId: {
      type: String, 
      default: '',
    },
    noBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalActive: false,
      typeOptions: [
          // 0 = ฝาก
          // 1 = แจ้งถอน
          // 2 = OTP ถอน
          // 3 = emergency
          // 4 = แก้ไขข้อมูลยูสเซอร์
          // 5 = แจ้งเตือนโยกเงิน
        { value: 0, name: 'ฝาก'},
        { value: 1, name: 'ถอน'},
        { value: 2, name: 'OTP ถอน'},
        { value: 3, name: 'Emergency'},
        { value: 4, name: 'แก้ไขข้อมูลยูสเซอร์'},
        { value: 5, name: 'โยกเงินอัตโนมัติ'}
      ],
      lineForm: {
        agentId: '',
        type: 0,
        name: '',
        token: '',
      },
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.line.isFetchingLineNotification,
      isLoading: (state) => state.line.isCreatingLineNotification || state.line.isUpdatingLineNotification,
      detail: (state) => state.line.lineNotificationDetail,
    }),
  },
  watch: {
    selectedId(val) {
      if(val) {
        this.fetchData()
      }
    },
    detail(val){
      if(val){
        this.setData()
      }
    },
  },
  created() {
    this.lineForm = {
      agentId: '',
      type: 0,
      name: '',
      token: '',
    }
  },
  methods: {
    ...mapActions(['createLineNotification', 'fetchLineNotificationById', 'updateLineNotification']),
    fetchData() {
      if(this.selectedId){
        this.fetchLineNotificationById(this.selectedId)
      }
    },
    setData() {
      if (this.detail && this.detail.id) {
        const data = JSON.parse(JSON.stringify(this.detail))
        this.lineForm = {
          id: data.id,
          agentId: data.agentId || '',
          type: data.type || 0,
          name: data.name || '',
          token: data.token || '',
        }
      } else {
        this.lineForm = {
          agentId: '',
          type: 0,
          name: '',
          token: '',
        }
      }
    },
    toggleModal() {
      this.isModalActive = !this.isModalActive
    },
    onCloseModal() {
      this.isModalActive = false
    },
    async onSubmit() {
      if(this.lineForm.id){
        await this.updateLineNotification({id: this.lineForm.id, data: this.lineForm})
        this.onCloseModal()
        this.lineForm = {
          agentId: '',
          type: 0,
          name: '',
          token: '',
        }
      } else {
        await this.createLineNotification(this.lineForm)
        this.onCloseModal()
        this.lineForm = {
          agentId: '',
          type: 0,
          name: '',
          token: '',
        }
      }
    },
  },
}
</script>
